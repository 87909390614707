import React from 'react'
import { Link } from 'gatsby'
import {
  Container,
  Button,
  FooterSection
} from '../styles/components'
import logo from '../images/logo_text.png'
//import { Facebook } from '@styled-icons/fa-brands/Facebook'
//import { Linkedin } from '@styled-icons/fa-brands/Linkedin'
//import { Instagram } from '@styled-icons/fa-brands/Instagram'
import { Phone } from '@styled-icons/icomoon/Phone'
import { Mobile } from '@styled-icons/icomoon/Mobile'
import { Location } from '@styled-icons/icomoon/Location'

export const Footer = () => {
  return (
    <footer>
      <Container>
        <FooterSection>
          <div>
            <img src={logo} alt="logo" />
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
            </ul>
          </div>
          <div className="contactInfo">
            <div>
              <a href="tel:+20222916046">
                <Phone size={24} />
                <span>+20 2 22 916 046</span>
              </a>
            </div>
            <div>
              <a href="tel:+201501618185">
                <Mobile size={24} />
                <span>+20 150 16 18 18 5</span>
              </a>
            </div>
            <div>
              <a href="https://goo.gl/maps/VTiu225tb9MqUumn8" target="__blank">
                <Location size={24} />
                <span>39 Beirut St, Heliopolis, Cairo</span>
              </a>
            </div>
            <div>
              <Link to="/contact">
                <Button>Contact Us</Button>
              </Link>
            </div>
          </div>
          {/*<div>
            <h4 textAlign="center">Follow us</h4>
            <ul style={{ paddingTop: "10px" }}>
              <li>
                <a href="">
                  <Facebook size={24} />
                </a>
              </li>
              <li>
                <a href="">
                  <Linkedin size={24} />
                </a>
              </li>
              <li>
                <a href="">
                  <Instagram size={24} />
                </a>
              </li>
            </ul>
          </div>*/}
        </FooterSection>
        <div style={{ paddingTop: "10px" }}>
          <span style={{ display: "block", textAlign: "center" }}>
            © 2021, Turnkey for Trading and Contracting, ESC. All Rights Reserved.
          </span>
        </div>
      </Container>
    </footer>
  )
}