import React, { useState, useEffect } from 'react'
import { NavBar } from './index'
import logo from '../images/logo_white.png'
import Carousel from 'react-bootstrap/Carousel'
import { useMediaQuery } from 'react-responsive'
import { HeroImg } from '../styles/components'
import hero1 from '../images/hero/hero1.jpg'
import hero1Mob from '../images/hero/hero1_mob.jpg'
import hero2 from '../images/hero/hero2.jpg'
import hero2Mob from '../images/hero/hero2_mob.jpg'
import hero3 from '../images/hero/hero3.jpg'
import hero3Mob from '../images/hero/hero3_mob.jpg'
import hero4 from '../images/hero/hero4.jpg'
import hero4Mob from '../images/hero/hero4_mob.jpg'
import hero5 from '../images/hero/hero5.jpg'
import hero5Mob from '../images/hero/hero5_mob.jpg'

export const HeroBanner = () => {
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [img4, setImg4] = useState(null);
  const [img5, setImg5] = useState(null);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {
    if (isTabletOrMobile) {
      setImg1(hero1Mob);
      setImg2(hero2Mob);
      setImg3(hero3Mob);
      setImg4(hero4Mob);
      setImg5(hero5Mob);
    } else {
      setImg1(hero1);
      setImg2(hero2);
      setImg3(hero3);
      setImg4(hero4);
      setImg5(hero5);
    }
  }, [])

  return (
    <div className="hero-banner">
      <NavBar activePage="home" background="transparent" logo={logo} />
      <div className="phoneHero"></div>
      {img3 &&
      <Carousel>
        <Carousel.Item>
          <HeroImg
            className="d-block w-100"
            src={img1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <HeroImg
            className="d-block w-100"
            src={img2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <HeroImg
            className="d-block w-100"
            src={img3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <HeroImg
            className="d-block w-100"
            src={img4}
            alt="Fourth slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <HeroImg
            className="d-block w-100"
            src={img5}
            alt="Fifth slide"
          />
        </Carousel.Item>
      </Carousel>}
    </div>
  )
}
