import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

export const NavBar = ({ activePage, background, logo }) => {
  return (
    <Navbar bg={background} expand="lg" sticky="top">
      <Navbar.Brand href="/">
        <img
          src={logo}
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about" id={activePage === 'about' ? "activeLink" : null}>About</Nav.Link>
          <Nav.Link href="/gallery" id={activePage === 'gallery' ? "activeLink" : null}>Gallery</Nav.Link>
          <Nav.Link href="/contact" id={activePage === 'contact' ? "activeLink" : null}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
