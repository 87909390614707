import styled, { css } from 'styled-components'

export const PaddingMarginProps = css`
  ${props => props.center && "margin: 0 auto;"}
  padding-top: ${props => props.pt};
  padding-left: ${props => props.pl};
  padding-bottom: ${props => props.pb};
  padding-right: ${props => props.pr};
  padding: ${props => props.p} ${props => props.p};
  margin-top: ${props => props.mt};
  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb};
  margin-right: ${props => props.mr};
  margin: ${props => props.m} ${props => props.m};
`
export const FontSizeProp = css`
  font-size: ${props => props.fontSize};
`
export const TextAlignProp = css`
  text-align: ${props => props.textAlign};
`

export const H1 = styled.h1`
  ${PaddingMarginProps}
  ${FontSizeProp}
  ${TextAlignProp}
`
export const HeroImg = styled.img`
  filter: brightness(50%);
`
export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;
  ${PaddingMarginProps}
  ${TextAlignProp}
`
export const TwoColumnContainer = styled(Container)`
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
  direction: ${props => props.reverse && "rtl"};
  margin: 50px auto;
  align-items: center;
  div, img {
    direction: ltr;
    margin: auto;
  }
  h1 {
    font-size: 36px;
    text-align: left;
    margin-bottom: 20px;
  }
  hr {
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    line-height: 150%;
    text-align: justify;
  }
  @media (max-width: 959px) {
    display: block;
    direction: ltr;
    h1 {
      margin-top: 20px;
      font-size: 28px;
    }
    p {
      font-size: 14px;
    }
    margin: 20px auto;
  }
`
export const Button = styled.button`
  background: #EA3836;
  padding: 15px;
  border: none;
  border-radius: 5px;
  outline: 0;
  font-size: 16px;
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  cursor: pointer;
  color: white;
  ${PaddingMarginProps}
`
export const FooterSection = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid white;
  padding: 60px auto;
  img {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  ul {
    padding: 0;
    list-style-type: none;
    li {
      text-align: center;
      margin: 20px 0;
    }
  }
  a {
    color: white;
  }
  .contactInfo {
    margin: auto;
    text-align: center;
    div {
      padding: 15px 0;
    }
    span {
      padding: 0 10px;
    }
  }
  @media (max-width: 959px) {
    display: block;
    direction: ltr;
  }
`
export const FeatureHero = styled.div`
  position: relative;
  max-height: 700px;
  overflow: hidden;
  background-color: white;
  padding-bottom: 5rem;
  section {
    width: 95vw;
    margin: 90px auto 0 auto;
    display: grid;
    template-columns: 1fr;
    align-items: center;
    span {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1em;
    }
    h1 {
      font-size: 54px;
      text-align: center;
      max-width: 700px;
      margin-bottom: 8px;
    }
    hr {
      margin-bottom: 30px;
    }
    p {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      max-width: 800px;
      text-align: justify;
      font-size: 18px;
      line-height: 150%;
    }
  }
  @media (max-width: 959px) {
    section {
      h1 {
        font-size: 56px;
      }
    }
  }
  @media (max-width: 599px) {
    margin-bottom: 40px;
    height: auto;
    section {
      h1 {
        font-size: 42px;
        margin-bottom: 25px;
      }
      p {
        width: 75%;
      }
    }
  }
`
export const FeatureHeroBg = styled.div`
  position: relative;
  max-height: 700px;
  overflow: hidden;
  background: transparent;
  section {
    width: 95vw;
    margin: 100px auto;
    display: grid;
    template-columns: 1fr;
    align-items: center;
    span {
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1em;
    }
    h1 {
      font-size: 54px;
      text-align: center;
      max-width: 80%;
      margin-bottom: 8px;
      color: white;
    }
    p {
      margin: 10px auto;
      width: 60%;
      max-width: 800px;
      text-align: center;
      font-size: 28px;
      line-height: 150%;
      color: white;
    }
  }
  @media (max-width: 959px) {
    section {
      h1 {
        font-size: 56px;
      }
    }
  }
  @media (max-width: 599px) {
    margin-bottom: 40px;
    height: auto;
    section {
      h1 {
        font-size: 42px;
        margin-bottom: 25px;
      }
      p {
        width: 75%;
      }
    }
  }
`
export const ContactForm = styled.form`
  margin: 0 auto 50px auto;
  max-width: 600px;
  padding: 1.5em;
  input, textarea {
    display: block;
    border-radius: 5px;
    background-color: white;
    height: 36px;
    margin: 20px auto;
    width: 90%;
    padding: 0 5%;
    border: 1px solid black;
    font-family: museo-sans, sans-serif;
    font-weight: 300;
    outline: 0;
  }
  textarea {
    height: 100px;
  }
  input:focus, textarea:focus {
    border: 1px solid #EA3836;
  }
  button {
    display: flex;
    margin: 10px auto 0 auto;
  }
  @media (max-width: 390px) {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 1.5em 0;
    input,
    select,
    button,
    div {
      max-width: 90vw !important;
    }
    input,
    span,
    margin-bottom: 0;
  }
`
